import { Route, RouteParts, Routes } from '@/src/modules/common/routes/common.routes.types';

export const routesParts: Record<RouteParts, string> = {
	[RouteParts.WorkspaceId]: '[workspaceId]',
	[RouteParts.EventId]: '[eventId]',
	[RouteParts.UserId]: '[userId]',
};

export const routes: Record<Routes, Route> = {
	[Routes.Home]: {
		path: `/`,
		private: false,
	},
	[Routes.WorkspaceHome]: {
		path: `/${routesParts.workspace}`,
		private: true,
	},
	[Routes.Dashboard]: {
		path: `/${routesParts.workspace}/dashboard`,
		private: true,
	},
	[Routes.Settings]: {
		path: '/settings',
		private: true,
	},
	[Routes.Support]: {
		path: '/support',
		private: false,
	},
	[Routes.Company]: {
		path: '/company',
		private: true,
	},
	[Routes.CreateWorkspace]: {
		path: '/workspace/create',
		private: true,
	},
	[Routes.EditWorkspace]: {
		path: `/${routesParts.workspace}/edit`,
		private: true,
	},
	[Routes.WorkspaceUsers]: {
		path: `/${routesParts.workspace}/users`,
		private: true,
	},
	[Routes.WorkspaceUserDetails]: {
		path: `/${routesParts.workspace}/users/details/${routesParts.user}`,
		private: true,
	},
	[Routes.WorkspaceEvents]: {
		path: `/${routesParts.workspace}/events`,
		private: true,
	},
	[Routes.CreateEvent]: {
		path: `/${routesParts.workspace}/events/create`,
		private: true,
	},
	[Routes.Event]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}`,
		private: true,
	},
	[Routes.EventStatus]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/status`,
		private: true,
	},
	[Routes.EventReport]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/report`,
		private: true,
	},
	[Routes.EventConfig]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/config`,
		private: true,
	},
	[Routes.EventTeam]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/team`,
		private: true,
	},
	[Routes.EventIntegrations]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/integrations`,
		private: true,
	},
	[Routes.EventSchedule]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/schedule`,
		private: true,
	},
	[Routes.EventDesign]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/design`,
		private: true,
	},
	[Routes.EventUsers]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/users`,
		private: true,
	},
	[Routes.EventUserDetails]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/users/details/${routesParts.user}`,
		private: true,
	},
	[Routes.EventActivities]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/activities`,
		private: true,
	},
	[Routes.EventPublications]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/publications`,
		private: true,
	},
	[Routes.EventRatings]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/ratings`,
		private: true,
	},
	[Routes.EventSupportCalendar]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/support-calendar`,
		private: true,
	},
	[Routes.EventScheduleLive]: {
		path: `/${routesParts.workspace}/events/${routesParts.event}/schedule-live`,
		private: true,
	},
};

// export const matchLinkWithRouteWithId = (
// 	links: string[] | null,
// 	route: Routes,
// 	id: number,
// ): boolean => (links === null ? false : `${links[0]}\\${links[1]}` === `${route}\\${id}`);

// export const matchLastLinksPart = (route: Routes, part: string | undefined): boolean =>
// 	part === undefined ? false : route.endsWith(part);

export const urlWithId = (path: string, match: string, id: number | string | undefined) =>
	path.replace(match, id?.toString() ?? 'undefined');

export const urlWithIds = (
	path: string,
	matches: {
		match: string;
		value?: number | string;
	}[],
) =>
	matches.reduce(
		(previousValue, currentValue) =>
			urlWithId(previousValue, currentValue.match, currentValue.value),
		path,
	);
